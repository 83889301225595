import {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {graphql} from 'gatsby'
import {useI18next} from 'gatsby-plugin-react-i18next'
import {isEmpty} from 'lodash-es'
import {FullPageBackground} from 'uikit'
import PayForm from '../components/screens/PayForm'
import Paywall from '../components/screens/Paywall'
import PaymentResult from '../components/screens/PaymentResult'
import {INITIAL_CONSENT, useConsent} from '../hooks/useConsent'


const PaymentPage = ({data, params, location}) => {
  const [isLoading, setLoading] = useState(false)
  const {consent} = useConsent()


  const {navigate} = useI18next()
  const pageData = data?.strapiPaymentPage

  const searchParams = new URLSearchParams(location.search)
  const externalId = searchParams.get('externalId')

  useEffect(() => {
    const facebookTrackerRequest = async () => {
      if (typeof window !== 'undefined') {
        // eslint-disable-next-line no-process-env
        return await window.fetch(`${process.env.GATSBY_API_URL}/api/checkout/${pageData?.strapi_id}`, {
          method: 'POST',
        }).catch(() => {
          throw new Error('Server unavailable')
        })
      }
    }
    facebookTrackerRequest()
  })

  const handleFormSubmit = async (values) => {
    const {cookies, ...restValues} = values
    consent(cookies ? INITIAL_CONSENT : {analyticalCookies: false, marketingCookies: false})

    if (typeof window !== 'undefined') {
      setLoading(true)
      // eslint-disable-next-line no-process-env
      return await window.fetch(`${process.env.GATSBY_API_URL}/api/participants`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          data: {
            paymentPageId: pageData?.strapi_id,
            referrer: window.location.href,
            ...restValues,
          },
        }),
      }).catch(() => {
        setLoading(false)
        throw new Error('Server unavailable')
      }).then((res) => {
        return res.json()
      }).then((res) => {
        const {token} = res
        setLoading(false)
        if (token) {
          return navigate(`${pageData.slug}/${token}`)
        }
      })
    }
  }

  const handleWallSubmit = async (activePackage, customFieldValue) => {
    if (typeof window !== 'undefined') {
      setLoading(true)

      try {
        const {checkoutUrl} = await window.fetch(
        // eslint-disable-next-line no-process-env
          `${process.env.GATSBY_API_URL}/api/participants/${params?.uuid}`,
          {
            method: 'PUT',
            headers: {
              'Content-type': 'application/json',
            },
            body: JSON.stringify({
              packageId: activePackage,
              customField: customFieldValue,
            }),
          }
        ).then((res) => res.json())

        if (checkoutUrl) {
          window.location.href = checkoutUrl
        }

        setLoading(false)
      } catch (e) {
        console.error(e)
      }
    }
  }

  if (externalId) {
    return (
      <FullPageBackground>
        <PaymentResult externalId={externalId} pageData={pageData} params={params} />
      </FullPageBackground>
    )
  }

  if (!isEmpty(params.uuid)) {
    return (
      <FullPageBackground>
        <Paywall
            pageData={pageData}
            isLoading={isLoading}
            setLoading={setLoading}
            onSubmit={handleWallSubmit}
        />
      </FullPageBackground>
    )
  }

  return (
    <FullPageBackground>
      <PayForm
          pageData={pageData}
          isLoading={isLoading}
          onSubmit={handleFormSubmit}
      />
    </FullPageBackground>
  )
}

export const query = graphql`
  query ($language: String!, $id: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    strapiPaymentPage(id: {eq: $id}) {
      strapi_id
      title
      slug
      CustomField {
        visibility
        label
      }
      content {
        data {
          childMarkdownRemark {
            html
          }
        }
      }
      thankYouContent {
        data {
          childMarkdownRemark {
            html
          }
        }
      }
      ThankYouButton {
        link
        text
      }
      NextButton
      paywallContent {
        data {
          childMarkdownRemark {
            html
          }
        }
      }
      packages {
        title
        order
        highlight
        features {
          data {
            childMarkdownRemark {
              html
            }
          }
        }
        currency
        image {
          alternativeText
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        price
        id
        strapi_id
      }
      firstNameOptions {
        show
        required
      }
      lastNameOptions {
        show
        required
      }
      emailOptions {
        show
        required
      }
      phoneOptions {
        show
        required
      }
      addressOptions {
        show
        required
      }
      cityOptions {
        show
        required
      }
      postCodeOptions {
        show
        required
      }
      brand {
        strapi_id
        facebookDomainVerification
        brandLogo {
          alternativeText
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                transformOptions: {fit: COVER}
                placeholder: BLURRED
              )
            }
          }
        }
        email
        gdprLinkText
        gdprLink
        socials {
          icon
          link
        }
        footerLogo {
          alternativeText
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                transformOptions: {fit: COVER}
                placeholder: BLURRED
              )
            }
          }
        }
      }
    }
  }
`

PaymentPage.propTypes = {
  data: PropTypes.object,
  params: PropTypes.object,
  location: PropTypes.object,
}

export default PaymentPage
